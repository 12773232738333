import type { SectionLoader } from '#section/loader_types'
import { BOARD_SECTIONS_POSITION_FRAGMENT } from '#section/fragment'
import type { CreateSectionParams, SectionUpdatePayload } from '#section/types'

export const useReadSectionsFromCache = (params: { boardId: string }) => {
  const { sections, loadSections } = useBoardSectionsLoader()
  loadSections(params.boardId)
  return sections.value
}

export const useCreateSection = () => {
  const { add: bypassViewportCheckSection } = useObserverState()
  const { getOptimisticSection } = useSectionDataConvert()

  const createSection = async (params: CreateSectionParams) => {
    const {
      name,
      boardId,
      position = undefined,
      targetSectionId = null,
      placement = 'bottom',
    } = params

    // Get new section position
    const sections = useReadSectionsFromCache({
      boardId,
    })
    let newPosition: number | undefined = undefined

    // Use position if provided
    if (position !== undefined && position !== null) {
      newPosition = position
    } else if (targetSectionId && ['left', 'right'].includes(placement)) {
      // If the target section is provided and the placement is 'left' or 'right'
      const targetSection = sections.find(
        (section) => section.id === targetSectionId
      )
      if (targetSection) {
        let targetIndex = sections.indexOf(targetSection)
        if (placement === 'right') {
          targetIndex += 1
        }

        newPosition = calculateItemPosition(targetIndex, sections)
      }
    }

    // Add section to the end of the list for the 'bottom' placement or all other cases
    if (!newPosition) {
      newPosition = (sections.pop()?.position || 0) + 1
    }

    const payload: SectionUpdatePayload = {
      name,
      board: boardId,
      position: newPosition,
    }
    const { mutate, error, loading } = useAddUpdateSectionMutation({
      variables: {
        input: payload,
      },
      optimisticResponse: {
        addUpdateSection: {
          section: getOptimisticSection(null, payload) as SectionLoader,
        },
      },
      update: (_, { data }) => {
        if (data?.addUpdateSection?.section) {
          // Update the cache with the new item
          const { listCache } = useSectionSync()
          listCache.add(boardId, data.addUpdateSection.section)
          /**
           * This is a workaround to prevent the section from being hidden when it is created.
           */
          bypassViewportCheckSection(data.addUpdateSection.section.id)
        }
      },
    })

    if (loading.value) {
      return
    }

    const data = await mutate()
    if (error.value || !data?.data?.addUpdateSection) {
      return {
        error,
      }
    }

    return { data }
  }

  return {
    createSection,
  }
}

export const useSectionMove = () => {
  const { client } = useApolloClient()
  const { currentBoard } = useWorkspaceSharedState()
  const { getSectionIdentifier } = useSectionDataConvert()

  const updateOptimisticPosition = (
    sectionId: string,
    boardId: string,
    position: number
  ) => {
    client.cache.modify({
      id: getSectionIdentifier(sectionId),
      fields: {
        boardId: () => boardId,
        position: () => position,
      },
      optimistic: true,
    })
  }

  const moveSectionToIndex = async (
    sectionId: string,
    targetIndex: number,
    boardId = currentBoard.value.id
  ) => {
    const fragmentOption = {
      id: getSectionIdentifier(sectionId),
      fragment: BOARD_SECTIONS_POSITION_FRAGMENT,
    }
    const sections = useReadSectionsFromCache({ boardId })
    const section = client.readFragment(fragmentOption)
    const sectionIndex = sections.findIndex(
      (section) => section.id === sectionId
    )
    if (!section || sectionIndex === targetIndex) {
      return
    }

    const position = calculateItemPosition(targetIndex, sections, section)
    updateOptimisticPosition(sectionId, boardId, position)
    const { mutate, error } = useUpdateSectionPositionMutation({
      variables: {
        sectionId,
        position,
      },
    })
    const data = await mutate()
    if (error.value || !data?.data?.updateSectionPosition.success) {
      return updateOptimisticPosition(
        sectionId,
        section.boardId,
        section.position
      )
    }
  }

  return {
    moveSectionToIndex,
  }
}

export const useUpdateSection = () => {
  const { client } = useApolloClient()
  const { getSectionIdentifier, getOptimisticSectionFields } =
    useSectionDataConvert()

  const updateSection = async (id: string, payload: SectionUpdatePayload) => {
    client.cache.modify({
      id: getSectionIdentifier(id),
      fields: getOptimisticSectionFields(payload),
      optimistic: true,
    })

    const { mutate } = useAddUpdateSectionMutation({
      variables: {
        input: {
          id,
          ...payload,
        },
      },
      update: (_, { data }) => {
        if (data?.addUpdateSection) {
          const { listCache } = useSectionSync()
          listCache.update(
            data.addUpdateSection.section.boardId,
            data?.addUpdateSection.section
          )
        }
      },
    })
    await mutate()
  }

  return {
    updateSection,
  }
}
